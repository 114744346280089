import React, { useState } from "react";
import classes from '../marketplace/Style/MarketplaceFilter.module.css';
import { RootState } from "Store/mainStore";
import { useSelector, useDispatch } from "react-redux";
import Button, {
    ButtonText,
    ButtonIcon,
} from '@nokia-csf-uxr/ccfk/Button';
import _ from "lodash";
import UserDialogModal from "./user-management/UserDialogModal";
import SearchBox from "Pages/marketplace/common/SearchBox";
import SelectBox from "Pages/subscription/Custom/SelectBox";
import userStyles from "../admin/user-management/Style/UserManagement.module.css";
import { setUserFilters } from "Store/contentState/userManagementSlice";
//import SplitButton
import { ListItemBasic, ListItemText, ListItemIcon } from "@nokia-csf-uxr/ccfk/List";
import {ReactComponent as AddIcon} from '@nokia-csf-uxr/ccfk-assets/icons/latest/add.svg';
import { setProp } from "Store/Actions";


// const options = [
//     [<SettingsIcon />, 'Action 1'],
//     [<SettingsIcon />, 'Action 2'],
//     [<SettingsIcon />, 'Action 3']
// ]

const AdminFilter = () => {
    const [showAddUserDialog, setShowAddUserDialog] = useState(false);
    const userManagementSelector = useSelector((state: RootState) => state.userManagement);
    const dispatch = useDispatch();


    const handleRoleSelection = (selectedRole) => {
        if (selectedRole !== "") {
            dispatch(setUserFilters({ filterName: "role", filterValue: selectedRole }));
        } else {
            dispatch(setUserFilters({ filterName: "role", filterValue: '' }));
        }
    }

    const modifiedRoleData = userManagementSelector?.metaData?.commonRoles?.map((role) => {
        return {
            id: role.id,
            value: role.name,
        }
    })

    return (
        <>
            {showAddUserDialog && (
                <UserDialogModal onClose={() => setShowAddUserDialog(false)} mode="add" selectedUserData="" />
            )}
            <div className={userStyles.adminFilterContainer}>
                <div className={userStyles.adminFiltersSection}>
                    <SearchBox placeholder="Search" />
                    <div className={classes.verticalDivider} style={{ margin: '0 .3rem 0 .3rem' }}></div>
                    <div className={classes.filterSection}>
                        <SelectBox
                            // disabled={marketplaceSelector.selectedDeviceID !== '' ? true : false }
                            placeholder="Role name"
                            maxWidth={true}
                            caseInsentiveMatching={true}
                            selectedItem={userManagementSelector.userFilters.role}
                            data={modifiedRoleData}
                            onUpdateSelection={handleRoleSelection}
                            isLoading={false}
                        />
                    </div>

                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div className={classes.filterTabs}>
                        <Button
                            variant="call-to-action"
                            onClick={() => dispatch(setProp({ slice: 'userManagement', key: 'showUserDialogSheet', value: true }))}
                        >
                            <ButtonIcon><AddIcon style={{color:'white', height:'20px'}}/></ButtonIcon>
                            <ButtonText>New</ButtonText>

                        </Button>
                    </div>
                    {/* <div>
                        {options.map((option, index) => (
                            <ListItemBasic key={index}>
                                <ListItemIcon>{option[0]}</ListItemIcon>
                                <ListItemText>{option[1]}</ListItemText>
                            </ListItemBasic>
                        ))}
                    </div> */}
                </div>
            </div>

        </>

    )
}

export default AdminFilter;