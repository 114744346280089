import React, { useState, useEffect, useRef } from "react";
import Dialog, {
    DialogContent,
    DialogFooter,
    DialogTitle,
} from "@nokia-csf-uxr/ccfk/Dialog";
import { Button, TextInput } from "@nokia-csf-uxr/ccfk";
import CloseCloseIcon from '@nokia-csf-uxr/ccfk-assets/latest/CloseCloseIcon';
import Checkbox, { CheckboxLabelContent } from '@nokia-csf-uxr/ccfk/Checkbox';
import FieldSet, { Legend, FieldSetContent } from '@nokia-csf-uxr/ccfk/FieldSet';
import Label from '@nokia-csf-uxr/ccfk/Label';
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedPersonas, updateSelectedViewAccess, setUsersLoading, setUsers, clearUserModel, updateDataElement, updateRoles, setUserInputFields } from "Store/contentState/userManagementSlice";
import { RootState } from "Store/mainStore";
import SelectBox from "Pages/subscription/Custom/SelectBox";
import OOT from "Constants/services/oot";

import _findIndex from 'lodash/findIndex';
// FF icon
import DataElementList from "./DataElementList";
import InlineFeedbackMessage from '@nokia-csf-uxr/ccfk/InlineFeedbackMessage';
import paginationSlice from "Store/contentState/paginationSlice";
import { showModal } from "Store/auth";
import { useUserActions } from "./hooks/useUserActions";

import DialogSheet, {
    DialogSheetContentContainer,
    DialogSheetTitle,
  } from '@nokia-csf-uxr/ccfk/DialogSheet';

import { Typography } from "@nokia-csf-uxr/ccfk";
 



const DIALOG_HEIGHT = "90%";
const DIALOG_WIDTH = "90%";

const CONFIRM_ALERT_STYLE = {
    top: `calc((100vh - ${DIALOG_HEIGHT}) / 2)`,
    height: DIALOG_HEIGHT,
    minHeight: DIALOG_HEIGHT,
    left: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
    right: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
    width: DIALOG_WIDTH,
};

type IAddUserModalCloseProp = {
    onClose: () => void;
};


const UserDialogModal = ({ onClose, mode, selectedUserData }: IAddUserModalCloseProp & { mode: string } & { selectedUserData: any }) => {
    const appBannerRef = useRef(null);
    const appElementRef = useRef(null);
    const titleRef = useRef(null);
    
    const dispatch = useDispatch();
    const userManagementSelector = useSelector((state: RootState) => state.userManagement);
    const accountList = useSelector((state: RootState) => state.marketplace.partnerList);
    const authSlice = useSelector((state: RootState) => state.authSlice)
    const countries = useSelector((state: RootState) => state.marketplace.countryList);
    const subscriptionSelector = useSelector((state: RootState) => state.subscription);
    const personas = useSelector((state: RootState) => state.userManagement.metaData.persona);
    const viewAccess = useSelector((state: RootState) => state.userManagement.metaData.applicationList);
    const dataElementList = useSelector((state: RootState) => state.userManagement.metaData.dataElements);
    const commonRoles = useSelector((state: RootState) => state.userManagement.metaData.commonRoles);
    const paginationSelector = useSelector((state: RootState) => state.pagination);
    const { limitPerpage, offset } = paginationSelector.paginationProps;


    const [userSuccessMsg, setUserSuccessMsg] = useState("");
    const [userErrorMsg, setErrorMsg] = useState("");
    const [successShow, setSuccessShow] = useState(false);
    const [errorShow, setErrorShow] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [disableMarketplaceCheckbox, setDisableMarketplaceCheckbox] = useState(false);

    const getAllUsersList = useUserActions()
    

    useEffect(() => {
        if (mode === 'edit' && selectedUserData) {
       
            
            // Initialize state with selectedUserData
            dispatch(setUserInputFields({ field: 'fullName', value: selectedUserData.name }));
            dispatch(setUserInputFields({ field: 'emailAddress', value: selectedUserData.email }));
            dispatch(setUserInputFields({ field: 'userType', value: selectedUserData.userType || [] }));
            dispatch(setUserInputFields({ field: 'viewAccess', value: selectedUserData.viewAccess || [] }));
            dispatch(setUserInputFields({ field: 'dataElement', value: selectedUserData.dataElement || {} }));
            dispatch(setUserInputFields({ field: 'roles', value: selectedUserData.userRoles || {} }));
            dispatch(setUserInputFields({ field: 'oneStarNumber', value: selectedUserData.oneStarNum || [] }));
            dispatch(setUserInputFields({ field: 'threeStarNumber', value: selectedUserData.threeStarNum || [] }));
        }
    }, [mode, selectedUserData, dispatch]);

    const handlePersonaSelection = (personaId) => {
        const updatedUserTypes = userManagementSelector.userData.userType.includes(personaId)
            ? userManagementSelector.userData.userType.filter(id => id !== personaId)
            : [...userManagementSelector.userData.userType, personaId];
        dispatch(updateSelectedPersonas(updatedUserTypes));
    };

    const handleAccessSelection = (viewId) => {
        const updatedViewAccess = userManagementSelector.userData.viewAccess.includes(viewId)
            ? userManagementSelector.userData.viewAccess.filter(id => id !== viewId)
            : [...userManagementSelector.userData.viewAccess, viewId];
        dispatch(updateSelectedViewAccess(updatedViewAccess));
    };

    const handleDataElementSelection = (elementId, selectedValues) => {
        const updatedDataElementList = { ...userManagementSelector.userData.dataElement };

        if (selectedValues.length === 0) {
            delete updatedDataElementList[elementId];
        } else {
            updatedDataElementList[elementId] = [...selectedValues];
        }

        const updatedDataElementListWithStringKeys = Object.keys(updatedDataElementList).reduce((acc, key) => {
            acc[String(key)] = updatedDataElementList[key];
            return acc;
        }, {});

       
        dispatch(updateDataElement(updatedDataElementList));
    };

    const handleCheckboxChange = (element) => {
        const isSelected = !!userManagementSelector.userData.dataElement[element.id];
        handleDataElementSelection(element.id, isSelected ? [] : []);
    };

   

    const handleFullName = (name) => {
        dispatch(setUserInputFields({ field: 'fullName', value: name }));
    };

    const handleEmail = (email) => {
        dispatch(setUserInputFields({ field: 'emailAddress', value: email }));
    };

    const handleRolesSelection = (id, name) => {
        const updatedRoles = {
            ...userManagementSelector.userData.roles,
            [id]: userManagementSelector.userData.roles[id] === undefined ? name : undefined
        };
        dispatch(updateRoles(updatedRoles));
    };

    

    const handleOnClose = () => {
        dispatch(clearUserModel());
        if(authSlice.system.modal.variant == "ERROR"){
            dispatch(
                showModal({
                  msg: "",
                  panel: { title: "", msg: "" },
                  show: false
                })
              );
        }
        onClose(); 
    }

    const handlePartnerSelection = (__partner) => {
        dispatch(setUserInputFields({ field: 'oneStarNumber', value: [__partner] }));
        getAccountDetails(__partner);
    };

    const getAccountDetails = async (__partner) => {
        try {
            const response = await OOT.getAccountDetailsApi(__partner);
            const threeStarNumber = response.data[0]['three_star_id'];
            const billingCountry = response.data[0]['billingcountrycode'];
            checkAccountBillingCountry(billingCountry);
            if (response.status) {
                dispatch(setUserInputFields({ field: 'threeStarNumber', value: [threeStarNumber] }));
            } else {
                dispatch(setUserInputFields({ field: 'threeStarNumber', value: [] }));
            }
        } catch (error) {
            console.log(error);
        }
    };

    

    

    const checkAccountBillingCountry = (value) => {
        const filtered = countries.filter((item) => item.id === value);
        if (filtered.length > 0 && filtered[0].is_merchant_products_allowed === 0) {
            const selectedAccess = userManagementSelector.userData.viewAccess;
            // Unselect "marketplace", "quotations", and "orders" if they are selected
            const updatedAccess = selectedAccess.filter(viewId => viewId !== 'marketplace' && viewId !== 'quotations' && viewId !== 'orders'); 
            // Show error message if "marketplace", "quotations", or "orders" were selected
            if (selectedAccess.length !== updatedAccess.length) {
                setErrorShow(true);
                setErrorMsg("Selected view accesses have been unselected because billing country of the account is not allowed.");
            }
            // Disable the marketplace checkbox
            setDisableMarketplaceCheckbox(true);
            // Dispatch the updated view access
            dispatch(updateSelectedViewAccess(updatedAccess));
        } else {
            // If country allows merchant products, ensure the checkbox is enabled
            setDisableMarketplaceCheckbox(false);
            setErrorShow(false);
        }
    };

    const handleSubmit = async () => {
        setDisableBtn(true);
        let dataPayload = {};
        if(mode === 'add'){
             dataPayload = {
                "fullName": userManagementSelector.userData.fullName,
                "email": userManagementSelector.userData.emailAddress,
                "userType": userManagementSelector.userData.userType,
                "type": "user",
                "viewAccess": userManagementSelector.userData.viewAccess,
                "dataElement": userManagementSelector.userData.dataElement,
                "oneStarNum": userManagementSelector.userData.oneStarNumber,
                "threeStarNum": userManagementSelector.userData.threeStarNumber,
                "userRoles": userManagementSelector.userData.roles
            };
        }else{
            dataPayload = {
                "id":selectedUserData.id,
                "email": selectedUserData.email,
                "fullName":selectedUserData.name !== "" ? selectedUserData.name : userManagementSelector.userData.fullName,
                "oneStarNum": userManagementSelector.userData.oneStarNumber,
                "threeStarNum": userManagementSelector.userData.threeStarNumber,
                "userRoles": userManagementSelector.userData.roles,
                "role":{
                    "view":userManagementSelector.userData.viewAccess,
                    "roles": userManagementSelector.userData.roles,
                    "markets": {
                        "level1": [
                            "Nokia"
                        ]
                    },
                    "persona": userManagementSelector.userData.userType,
                    "one_star": userManagementSelector.userData.oneStarNumber,
                    "three_star": userManagementSelector.userData.threeStarNumber,
                    "data-element": userManagementSelector.userData.dataElement,
                },
                "type": "user",
            }
        }
        try {
            const response = await OOT.addEditUser(dataPayload, mode)

            if (response.status) {
                setSuccessShow(true);
                setUserSuccessMsg(response.msg);
                setTimeout(() => {   
                getAllUsersList();
                handleOnClose();
                }, 3000)
                // getAllUsers();
            } else {
                setErrorShow(true);
                setErrorMsg(response.errorMessage);
            }
        } catch (error) {
            setErrorShow(true);
            setErrorMsg(error.message);
        } finally {
            setDisableBtn(false);
        }
    };

    const handleAfterOpen = () => {
        titleRef && titleRef.current && titleRef.current.focus();
      };
   
    return (
        <Dialog isOpen={true} ariaHideApp={false} style={{ content: CONFIRM_ALERT_STYLE }}>
            <DialogTitle
                title={mode === "add" ? "Add User" : "Edit User"}
                buttonContent={<CloseCloseIcon />}
                buttonProps={{ onClick:  handleOnClose }}
            />
            <DialogContent isTopDividerVisible={true} isBottomDividerVisible={true}>
                <>
                    {userSuccessMsg && <InlineFeedbackMessage
                        variant="success"
                        size="medium"
                        closeButton
                        show={successShow}
                        onClose={() => setSuccessShow(false)}
                    >
                        {userSuccessMsg}
                    </InlineFeedbackMessage>}
                    {userErrorMsg && <InlineFeedbackMessage
                        variant="error"
                        size="medium"
                        closeButton
                        show={errorShow}
                        onClose={() => setErrorShow(false)}
                    >
                        {userErrorMsg}
                    </InlineFeedbackMessage>}
                </>
                <div style={{ width: '50%', padding: '.5rem', minHeight: '500px' }}>
                    <div style={{ marginBottom: '1rem' }}>
                        <Label style={{ fontSize: '14px', fontWeight: '700', lineHeight: '21px' }}>Full Name</Label>
                        <TextInput
                            id="full-name"
                            disabled={mode === "edit" && selectedUserData.name.trim() !== ""}
                            value={userManagementSelector.userData.fullName}
                            variant={"outlined"}
                            placeholder="Full Name"
                            onChange={(e) => handleFullName(e.target.value)}
                        />
                    </div>
                    <div style={{ marginBottom: '1rem' }}>
                        <Label style={{ fontSize: '14px', fontWeight: '700', lineHeight: '21px' }}>Email Address</Label>
                        <TextInput
                            id="email-address"
                            disabled={mode === "edit"}
                            value={userManagementSelector.userData.emailAddress}
                            variant={"outlined"}
                            placeholder="Email Address"
                            onChange={(e) => handleEmail(e.target.value)}
                        />
                    </div>
                    
                    <div style={{ marginBottom: '1rem' }}>
                        <Label style={{ fontSize: '14px', fontWeight: '700', lineHeight: '21px' }}>User Type</Label>
                        <div style={{ width: '50%', margin: 'var(--spacing-small)' }}>
                            <FieldSet>
                                <FieldSetContent variant="default">
                                    {personas.map(persona => (
                                        <Label key={persona.id}>
                                            <Checkbox
                                                checked={userManagementSelector.userData.userType.includes(persona.id)}
                                                onChange={() => handlePersonaSelection(persona.id)}
                                                inputProps={{ 'aria-label': persona.name, value: `checkbox${persona.id}` }}
                                            />
                                            <CheckboxLabelContent>{persona.name}</CheckboxLabelContent>
                                        </Label>
                                    ))}
                                </FieldSetContent>
                            </FieldSet>
                        </div>
                    </div>
                    <div style={{ marginBottom: '1rem' }}>
                        <Label style={{ fontSize: '14px', fontWeight: '700', lineHeight: '21px' }}>View Access</Label>
                        <div style={{ width: '50%', margin: 'var(--spacing-small)' }}>
                            <FieldSet>
                                <FieldSetContent variant="default">
                                    {viewAccess.map(view => (
                                        <Label key={view.id}>
                                            <Checkbox
                                                checked={userManagementSelector.userData.viewAccess.includes(view.id)}
                                                onChange={() => handleAccessSelection(view.id)}
                                                disabled={disableMarketplaceCheckbox &&
                                                    (view.id === "marketplace" ||
                                                        view.id === "quotations" ||
                                                        view.id === "orders")}
                                                inputProps={{ 'aria-label': view.name, value: `checkbox${view.id}` }}
                                            />
                                            <CheckboxLabelContent>{view.name}</CheckboxLabelContent>
                                        </Label>
                                    ))}
                                </FieldSetContent>
                            </FieldSet>
                        </div>
                    </div>
                    <div style={{ marginBottom: '1rem', width: '100%' }}>
                        <Label style={{ fontSize: '14px', fontWeight: '700', lineHeight: '21px' }}>Data Element</Label>
                        <div style={{ width: '100%', margin: 'var(--spacing-small)' }}>
                            {dataElementList.map(element => (
                                <div key={element.id} style={{ marginBottom: 'var(--spacing-small)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Label>
                                        <Checkbox
                                            checked={userManagementSelector.userData.dataElement[element.id]}
                                            onChange={() => handleCheckboxChange(element)}
                                            inputProps={{ 'aria-label': element.name, value: `checkbox${element.id}` }}
                                        />
                                        {/* <Checkbox
                                            checked={!!userManagementSelector.userData.dataElement[element.id]}
                                            onChange={() => handleCheckboxChange(element)}
                                            inputProps={{ 'aria-label': element.name, value: `checkbox${element.id}` }}
                                        /> */}
                                        <CheckboxLabelContent>{element.name}</CheckboxLabelContent>
                                    </Label>
                                    <DataElementList key={element.id} elementId={element.id} handleDataElementSelection={handleDataElementSelection} selectedValues={userManagementSelector.userData.dataElement[element.id]} actions={element['actions']}/>
                                   
                                </div>
                            ))}
                        </div>
                    </div>
                    <div style={{ marginBottom: '1rem' }}>
                        <Label style={{ fontSize: '14px', fontWeight: '700', lineHeight: '21px' }}>Roles</Label>
                        <div style={{ width: '75%', margin: 'var(--spacing-small)' }}>
                            <FieldSet>
                                <FieldSetContent variant="default">
                                    {commonRoles.map(view => (
                                        <Label key={view.id}>
                                            <Checkbox
                                                checked={userManagementSelector.userData.roles[view.id]}
                                                onChange={() => handleRolesSelection(view.id, view.name)}
                                                inputProps={{ 'aria-label': view.name, value: `checkbox${view.id}` }}
                                            />
                                            <CheckboxLabelContent>{view.name}</CheckboxLabelContent>
                                        </Label>
                                    ))}
                                </FieldSetContent>
                            </FieldSet>
                        </div>
                    </div>
                    <div style={{ marginBottom: '1rem' }}>
                        <Label style={{ fontSize: '14px', fontWeight: '700', lineHeight: '21px' }}>Account</Label>
                        <div style={{ width: '50%' }}>
                            <SelectBox
                                placeholder="Select Account"
                                maxWidth={true}
                                caseInsentiveMatching={true}
                                selectedItem={userManagementSelector.userData.oneStarNumber[0]}
                                data={subscriptionSelector.partnerList}
                                onUpdateSelection={handlePartnerSelection}
                                isLoading={subscriptionSelector.isPartnerLoading}
                            />
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogFooter>
                <Button onClick={handleOnClose}>Cancel</Button>
                <Button variant="call-to-action" onClick={handleSubmit} disabled={disableBtn}>Submit</Button>
            </DialogFooter>
        </Dialog>

        // <DialogSheet
        //         isOpen={true}
        //         // appElement={appElementRef}
        //         //onAfterOpen={handleAfterOpen}
        //       >
        //         <DialogSheetContentContainer
        //           isOpen={true}
        //           appHeaderRef={appBannerRef}
        //         >
        //           <DialogSheetTitle ref={titleRef} title="DialogSheet title goes here" />
        //           <DialogContent
        //             isTopDividerVisible
        //             isBottomDividerVisible
        //             aria-hidden="false"
        //           >
        //             <Typography typography="BODY">{""}</Typography>
        //           </DialogContent>
        //           <DialogFooter>
        //             <Button autoFocus onClick={handleOnClose}>CANCEL</Button>
        //             <Button variant="call-to-action" onClick={handleSubmit} disabled={disableBtn}>SAVE</Button>
        //           </DialogFooter>
        //         </DialogSheetContentContainer>
        //       </DialogSheet>
    );
};

export default UserDialogModal;

