import React, { useEffect } from "react";
import classes from '../Style/MarketplaceFilter.module.css';
import { RootState } from "Store/mainStore";
import { useSelector, useDispatch } from "react-redux";
import { setLoader, setProducts, setFilters, setProductTypes, setProductSubTypes } from "Store/contentState/marketplaceSlice";
import { resetPaginationsProps } from "Store/contentState/paginationSlice";
import SelectBox from "Pages/subscription/Custom/SelectBox";
import OOT from "Constants/services/oot";
import _ from "lodash";
import { getAccountCurrency } from "../Utils/commons";
import SearchBox from "../common/SearchBox";
import filterIcon from '../../../Assets/Icons/filter.svg';
import indexStyles from '../marketplace/Style/Index.module.css'


const MarketplaceFilter = () => {
  const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
  const dispatch = useDispatch();
  const accountId = marketplaceSelector.accountDetails[0]['id'];
  const accountDetails = marketplaceSelector.accountDetails;
  
  useEffect(() => {
    getProductSubTypes();
  }, [])


  const getProductTypes = async (value) => {
    dispatch(
      setLoader({
        key: "isProductTypeLoading",
        value: true,
      })
    );
    try {
      let currency = getAccountCurrency(accountDetails[0]['currency'], accountDetails[0]['currencyisocode']);
      let filter = `product_data.product_sub_type_id='${value}' and product_data.is_marketplace = 1`
      const productTypesRes = await OOT.getProductTypes(accountId, currency, filter);
      if (productTypesRes.status) {
        const updatedData = productTypesRes.data
          .filter((item) => item.product_id && item.product_id.trim() !== "")
          .map((item) => {
            return {
              "id": item.product_id,
              "value": item.product_id.includes("_") ? item.product_id.replace(/_/g, " ") : item.product_id
            };
          }).sort((a, b) => a.value.localeCompare(b.value));;
        dispatch(
          setLoader({
            key: "isProductTypeLoading",
            value: false,
          })
        );
        dispatch(setProductTypes({
          productTypes: updatedData,
        }))
      } else {
        dispatch(
          setLoader({
            key: "isProductTypeLoading",
            value: false,
          })
        );
      }
    } catch (error) {
      console.error(error)
      dispatch(
        setLoader({
          key: "isProductTypeLoading",
          value: false,
        })
      );
    }
  }

  const getProductSubTypes = async () => {
    dispatch(
      setLoader({
        key: "isProductSubTypeLoading",
        value: true,
      })
    );
    try {
      let filter = `${marketplaceSelector.productQueryFilter}`   //base query to be added here
      let currency = getAccountCurrency(accountDetails[0]['currency'], accountDetails[0]['currencyisocode'])
      const productSubTypesRes = await OOT.getProductSubTypes(accountId, currency, filter);
      if (productSubTypesRes.status) {
        const updatedData = productSubTypesRes.data
          .filter((item) => item.product_sub_type_id !== null && item.product_sub_type_id.trim() !== "")
          .map((item) => {
            return {
              "id": item.product_sub_type_id,
              "value": item.product_sub_type_id.includes("_") ? item.product_sub_type_id.replace(/_/g, " ") : item.product_sub_type_id
            };
          }).sort((a, b) => a.value.localeCompare(b.value));;
        dispatch(
          setLoader({
            key: "isProductSubTypeLoading",
            value: false,
          })
        );
        dispatch(setProductSubTypes({
          productSubTypes: updatedData,
        }))
      } else {
        dispatch(
          setLoader({
            key: "isProductSubTypeLoading",
            value: false,
          })
        );
      }
    } catch (error) {
      console.error(error)
      dispatch(
        setLoader({
          key: "isProductSubTypeLoading",
          value: false,
        })
      );
    }
  }




  const handleProductTypeSelection = (_productType) => {
    if (_productType !== '') {
      dispatch(setFilters({ filterName: 'productType', value: _productType }));
      dispatch(resetPaginationsProps());
    } else {
      dispatch(setFilters({ filterName: 'productType', value: "" }));
      dispatch(resetPaginationsProps());
    }
  }

 


  

  const handleSubTypeSelection = (_productSubtype) => {
    if (_productSubtype !== '') {
      dispatch(setFilters({ filterName: 'productSubType', value: _productSubtype }));
      getProductTypes(_productSubtype)
      dispatch(resetPaginationsProps());
    } else {
      dispatch(setFilters({ filterName: 'productSubType', value: "" }));
      dispatch(setProductTypes({
        productTypes: [],
      }))
      dispatch(resetPaginationsProps());
    }
  }


  return (

    <div className={classes.filterContainer}>
      <div style={{ display: 'flex', justifyContent: 'flex-start', width: '80%' }}>
        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><img src={filterIcon} style={{marginBottom:'5px'}}/></div>
        <div className={classes.searchBox}>
        <SearchBox placeholder="Search an item"/>
        </div>
      <div className={classes.verticalDivider} style={{margin:'0 .3rem 0 .3rem'}}></div> 
        <div className={classes.filterSelection}>
          <SelectBox
            // disabled={marketplaceSelector.selectedDeviceID !== '' ? true : false }
            placeholder="Product category"
            maxWidth={true}
            caseInsentiveMatching={true}
            selectedItem={marketplaceSelector?.filters?.selection?.productSubType}
            data={marketplaceSelector.productSubtypes}
            onUpdateSelection={handleSubTypeSelection}
            isLoading={marketplaceSelector.isProductSubTypeLoading}

          />
          {/* {true && (
                    <div className={classes.optionLoader}>
                        <CircularProgressIndicatorIndeterminate
                            strokeWidth={6}
                            role="alert"
                        />
                    </div>
                )} */}
        </div>
        <div className={classes.filterSelection}>
          <SelectBox
            // disabled={marketplaceSelector.selectedDeviceID !== '' ? true : false }
            placeholder="Product type"
            maxWidth={true}
            caseInsentiveMatching={true}
            selectedItem={marketplaceSelector?.filters?.selection?.productType}
            data={marketplaceSelector.productTypes}
            onUpdateSelection={handleProductTypeSelection}
            isLoading={marketplaceSelector.isProductTypeLoading}

          />
          {/* {marketplaceSelector.isProductTypeLoading && (
                    <div className={classes.optionLoader}>
                        <CircularProgressIndicatorIndeterminate
                            strokeWidth={6}
                            role="alert"
                        />
                    </div>
                )} */}
        </div>
        {/* <div className={classes.filterSelection} style={{ justifyContent: 'center' }}>
          <div style={{ display: 'flex' }}>
            <Checkbox
              variant="default"
              indeterminate={indeterminate}
              checked={marketplaceSelector.filters.selection.showOnlyNokiaProducts}
              onChange={(event) => {
                event.persist();
                setIsIndeterminate(false);
                
                dispatch(setFilters({ filterName: 'showOnlyNokiaProducts', value: !marketplaceSelector.filters.selection.showOnlyNokiaProducts }))
                dispatch(setProp({ slice: 'marketplace', key: 'showMerchantProducts', value: !marketplaceSelector.showMerchantProducts }))
              }}
              inputProps={{
                'aria-label': 'Check this to agree to terms'
              }}
            />
            <CheckboxLabelContent style={{ color: '#333333', fontSize: '14px', fontWeight: "700" }}>
              Nokia products
            </CheckboxLabelContent>
          </div>
        </div> */}
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', width: '20%' }}>
        {/* <div className={classes.filterTabs}>
         
          <Chip size="medium" style={{ backgroundColor: '#E8FBFF', border: '1px solid #80E6FF', marginRight: '.5rem', borderRadius: '15px' }}><ChipIcon><img src={nokiaCertified} /></ChipIcon><ChipLabel label="Nokia certified" /></Chip>
          <Chip size="medium" style={{ backgroundColor: '#EAE4FF', border: '1px solid #C0A5FF', marginRight: '.5rem', borderRadius: '15px' }}><ChipIcon><img src={certified} /></ChipIcon><ChipLabel label="Certified" /></Chip>
        </div> */}
      </div>


    </div>


  )
}

export default MarketplaceFilter