import React, { useState } from "react";
import UsersList from "./user-management/UsersList";
import Card from '@nokia-csf-uxr/ccfk/Card';
import TabsSection from '../marketplace/common/TabsSection';
import Users from "./user-management/Users";





const customTabData = [
  { tab: { children: 'Users', label: 'Tab One description' } },
  { tab: { children: 'Machines', label: 'Tab Two description' } },
  { tab: { children: 'White listed applications', label: 'Tab Three description' } }
];






const AdminBody = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  //const [renderedTabsData, setRenderedTabsData] = useState([0]);

  const tabContentComponents = [
    <Users key={0} type="user" isActive={activeTabIndex === 0} />,
    <Users key={1} type="machine" isActive={activeTabIndex === 1} />,
    <Users key={2} type="whitelisted_client" isActive={activeTabIndex === 2} />
];

  const handleTabSelect = (index) => {
    console.log('Tab selected:', index);
    setActiveTabIndex(index);
    // Only add to renderedTabsData if it hasn't been rendered before
    // if (!renderedTabsData.includes(index)) {
    //   setRenderedTabsData([...renderedTabsData, index]);
    // }
  };

 


  return (
    <>
      <Card style={{ padding: '1rem' }}>
        <div>
          {/* <TabsSection tabData={customTabData} tabContentComponents={[
            <Users key={activeTabIndex} type={tabContentComponents[activeTabIndex].type} />
          ]}
            //@ts-ignore
            onTabChange={handleTabSelect}
          /> */}
          <TabsSection tabData={customTabData} tabContentComponents={tabContentComponents}  //@ts-ignore
            onTabChange={handleTabSelect}/>
        </div>
      </Card>

    </>
  )
}

export default AdminBody;