import { createSlice } from "@reduxjs/toolkit";

 let initialState = {
    globalSearchText: ""
 }

 const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        setGlobalSearchText: (state, action) => {
            state.globalSearchText = action.payload;
        },
        clearGlobalSearchText: (state) => {
            state.globalSearchText = '';  // Clear global search text
        }
    }
});



 export default commonSlice.reducer;
 export const { setGlobalSearchText, clearGlobalSearchText } = commonSlice.actions;