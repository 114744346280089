import { createSlice } from "@reduxjs/toolkit";
import { setProp } from "Store/Actions";



const initialData = {
    users: [],
    isUsersLoading: false,
    userData: {
        fullName: "",
        emailAddress: "",
        userType: [],
        roles: {},
        dataElement: {},
        viewAccess: [],
        oneStarNumber: [],    //it will be array later
        threeStarNumber: []
    },
    metaData: {
        applicationList: [],
        commonRoles: [],
        dataElements: [],
        persona:[]
    },
    userFilters:{
        role:"",
        market:"",
        accountGroup:""
    },
    showUserDialogSheet:false

}


const userManagementSlice = createSlice({
    name: 'userManagement',
    initialState: initialData,
    reducers: {
        updateSelectedPersonas: (state, action) => {
            state.userData.userType = action.payload;
        },
        updateSelectedViewAccess: (state, action) => {
            state.userData.viewAccess = action.payload;
        },
        setFullName: (state, action) => {
            state.userData.fullName = action.payload
        },
        setUserInputFields: (state, action) => {
            const { field, value } = action.payload;
            state.userData[field] = value;
        },
        updateDataElement: (state, action) => {
            state.userData.dataElement = action.payload;
        },
        setUsers: (state, action) => {
            state.users = action.payload;
        },
        updateRoles(state, action) {
            state.userData.roles = action.payload; // Update the roles object directly
        },
        setUserMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        clearUserModel: (state) => {
            state.userData = initialData.userData
        },
        setUsersLoading: (state, action) => {
            state.isUsersLoading = action.payload
        },
        setUserFilters(state, action) {
            console.log(action.payload)
            const { filterName, filterValue } = action.payload;
            state.userFilters[filterName] = filterValue;
          },
       
    },
    extraReducers(builder) {
        builder.addCase(setProp, (state, action: {
            payload: any;
            type: string;
            slice: string;
        }) => {
            if (action.payload.slice === userManagementSlice.name) {
                state[action.payload.key] = action.payload.value;
            }
        });
    }
})


export const { updateSelectedPersonas, setUsersLoading, updateSelectedViewAccess, setUserFilters, setUserMetaData, updateRoles, setUserInputFields, setFullName, updateDataElement, setUsers, clearUserModel } = userManagementSlice.actions;
export default userManagementSlice.reducer;
