/* eslint-disable */
import React, { useState, useRef } from 'react';
import Typography from '@nokia-csf-uxr/ccfk/Typography';
import Tabs, { Tab, TabsContent } from "@nokia-csf-uxr/ccfk/Tabs";
import Card from '@nokia-csf-uxr/ccfk/Card';
import TabsSection from '../common/TabsSection';
import ActiveOrders from './ActiveOrders';
import ArchivedOrders from './Archived';

const customTabData = [
  { tab: { children: 'Active orders', label: 'Tab One description' } },
  { tab: { children: 'Archived orders', label: 'Tab Two description' } },

];

const tabContentComponents = [
  <ActiveOrders key={0} />,
  <ArchivedOrders key={1}/>
];


const OrdersBody = () => {

  return (
    <>
      <Card style={{padding:'1rem'}}>
      <TabsSection tabData={customTabData} tabContentComponents={tabContentComponents} />
      </Card>

    </>
  );
};

export default OrdersBody;