import React, { useEffect } from "react";
import UsersList from "./UsersList";
import AdminFilter from "../AdminFilter";
// import Machines from "./Machines";
// import WhiteListedClients from "./WhitelistedClients";
import { Card, Typography } from '@nokia-csf-uxr/ccfk';


const Users = (prop) => {
    return (
        <div>
            <Card style={{ padding: '.5rem', marginTop:'10px' }}>
            <AdminFilter/>
             <UsersList data={prop}/>
             </Card>
            {/* {prop.type === 'machine' && <Machines type={prop.type}/>}
            {prop.type === 'whitelisted_client' && <WhiteListedClients type={prop.type}/>} */}
            
            
        </div>
    )
}

export default Users;