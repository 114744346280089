
import React, { useState, useRef } from "react";
import DataGrid from '@nokia-csf-uxr/ccfk/DataGrid/DataGrid';
import { useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
import Checkbox, {
    CheckboxLabelContent,
} from '@nokia-csf-uxr/ccfk/Checkbox';
import Card from '@nokia-csf-uxr/ccfk/Card';
import { Typography } from "@nokia-csf-uxr/ccfk";



const UserRoles = () => {
    const applicationList = useSelector((state: RootState) => state.userManagement.metaData.applicationList);
    const roleList = useSelector((state: RootState) => state.userManagement.metaData.commonRoles);

    // const initialData = applicationList.map(page => ({
    //     page,
    //     permissions: roleList.reduce((acc, role) => {
    //       acc[role] = false; // Initialize all checkboxes as unchecked
    //       return acc;
    //     }, {})
    //   }));


    const initialData = applicationList.map(app => ({
        application: app, // Store the entire object { id, name }
        permissions: roleList.reduce((acc, role) => {
            acc[role.id] = false; // All checkboxes unchecked by default
            return acc;
        }, {})
    }));

    const [data, setData] = useState(initialData);
    const gridApi = useRef(null);




    // Function to handle checkbox change
    const handleCheckboxChange = (appId, roleId) => {
        setData(prevData =>
            prevData.map(row =>
                row.application.id === appId
                    ? { ...row, permissions: { ...row.permissions, [roleId]: !row.permissions[roleId] } }
                    : row
            )
        );
    };

    return (
        <Card style={{ padding: '1rem' }}>
            <Typography style={{ color: '#333333', fontSize: '18px', borderBottom: "1px solid #0000001F", fontWeight:'bold' }}>User Roles</Typography>
            <div style={{ width: '100%', height: '100%', marginTop: '10px' }}>

                <DataGrid
                    suppressMovableColumns={false}
                    suppressColumnMoveAnimation={false}
                    suppressRowClickSelection={false}
                    isRowDrag={false}
                    wrapperProps={{
                        style: {
                            width: "100%",
                        },
                    }}
                    disableAutoloadStyles
                    suppressContextMenu
                    onGridReady={(params) => {
                        gridApi.current = params.api;
                    }}
                    rowData={data} // The table data
                    columnDefs={[
                        // First column for application names
                        {
                            headerName: "Pages",
                            field: "application.name", // Access the name from the application object
                            minWidth: 150,
                            editable: false,
                            cellStyle: { textAlign: "left", padding: "10px" },
                        },
                        // Dynamic columns for roles with checkboxes
                        ...roleList.map((role) => ({
                            headerName: role.name, // Display role name in the header
                            field: `permissions.${role.id}`, // Access permission using role id
                            minWidth: 100,
                            cellRenderer: (params) => (
                                // <input
                                //     type="checkbox"
                                //     checked={params.data.permissions[role.id]}
                                //     onChange={() => handleCheckboxChange(params.data.application.id, role.id)}
                                // />z
                                <Checkbox
                                    variant="default"
                                    disabled={false}
                                    indeterminate={false}
                                    checked={params.data.permissions[role.id]}
                                    onChange={(event) => {
                                        event.persist();
                                        handleCheckboxChange(params.data.application.id, role.id)
                                        // dispatch(setProp({ slice: 'marketplace', key: 'nokiaTermsConditions', value: "I agree to the terms and conditions of the DAC Marketplace Agreement" }))
                                    }}
                                    inputProps={{
                                        'aria-label': 'Check this to agree to terms'
                                    }}
                                />
                            ),
                            cellStyle: { textAlign: "center" },
                        })),
                    ]}
                    key={"roles-table"}
                    defaultColDef={{
                        editable: false,
                        sortable: true,
                        resizable: true,
                        filter: false,
                        flex: 0,
                        minWidth: 100,
                    }}
                    overlayNoRowsTemplate="No applications available"
                    domLayout="autoHeight"
                    isInlineRowActions={false}
                    onFirstDataRendered={(params) => {
                        params.columnApi.autoSizeAllColumns();
                    }}
                />
            </div>
        </Card>
    );
};

export default UserRoles;
