import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import OOT from "Constants/services/oot";
import { useSubscriptionFilter } from "./subscription/hooks/useSubscriptionFilter";

import {
  setLoader,
  setPartnerList,
  setQuestionnaire,
  setInvoicingSubscriptionFrequency,
} from "Store/contentState/subscriptionSlice";

import Subscription from "Pages/subscription/Subscription";
import Marketplace from "./marketplace/Marketplace";
import CartPage from "./marketplace/CartPage";
import Account from "Pages/subscription/Account/index";
import { Login } from "Pages/Login";
import { useSubscriptionAuth } from "./subscription/hooks/useSubscriptionAuth";
import Unauthorized from "./Unauthorized";
import SelectedProductPage from "./marketplace/SelectedProductPage";
import Quotations from "./marketplace/quotations/Quotations";
import { RootState } from "Store/mainStore";
import {
  setUserAccessInfo,
  setCountryList,
} from "Store/contentState/marketplaceSlice";
import Loader from "Components/Elements/Loader";
import Admin from "./admin/Admin";
import { setUserMetaData } from "Store/contentState/userManagementSlice";
import { useMarketplaceFilter } from "./marketplace/hooks/useMarketplaceFilter";
import Orders from "./marketplace/orders/Orders";
import MarketplaceBody from "./marketplace/MarketplaceBody";
import CartBody from "./marketplace/CartBody";
import { setProp } from "Store/Actions";
import ProtectedRoute from "./marketplace/HOC/ProtectedRoute";
import { setPartnerList as setMarketplacePartnerList } from "Store/contentState/marketplaceSlice";
import UserRoles from "./admin/user-management/UserRoles";
import Roles from "./admin/user-management/Roles";

export const Landing = () => {
  const { useAuthState, useIsAuthorized } = useSubscriptionAuth();
  const { isAuthenticated, email } = useAuthState();
  const isAuthorized = useIsAuthorized(email);
  const authSelector = useSelector((state: RootState) => state.authSlice);
  const loginUserId = useSelector(
    (state: RootState) => state.authSlice.login.id
  );

  const marketplaceSelector = useSelector(
    (state: RootState) => state.marketplace
  );

  const dispatch = useDispatch();
  const { fetchSubscriptionNetworkData } = useSubscriptionFilter();
  const { fetchMarketplaceData } = useMarketplaceFilter();
  const location = useLocation();

  const [isDirectAccess, setIsDirectAccess] = useState({
    subscription: true,
    account: true,
  });

  const getUserInfo = async (loginUserId) => {
    // Handle default user role view
    let userRoleView = ["subscription", "account"];
    try {
      // <Get configured user access
      const response = await OOT.getUserAccessControlInfo(loginUserId);
      if (response.status && response.data.length > 0) {
        // Handle user role view
        userRoleView = response.data[0]["role"]["view"];
        if (userRoleView.includes("marketplace")) {
          getCountriesList();
          let cartInfo =
            response.data[0]["cartInfo"].length > 0
              ? response.data[0]["cartInfo"][0]["cart"]
              : [];
          dispatch(
            setProp({
              slice: "marketplace",
              key: "savedCartInfo",
              value: cartInfo,
            })
          );
          // dispatch(setProp({slice:'marketplace', key: 'cart', value: cartInfo.cart_items}))
        }
        if (response.data[0]["metadata"]) {
          dispatch(setUserMetaData(response.data[0]["metadata"]));
        }

        const hasAdministratorRole =
          "administrator" in response.data[0]["role"]["roles"];
        if (hasAdministratorRole) {
          userRoleView.push("admin");
          userRoleView.push("roles");
        }

        const __isDirectAccess = { ...isDirectAccess };

        if (!userRoleView.includes("subscription")) {
          __isDirectAccess.subscription = false;
        }
        if (!userRoleView.includes("account")) {
          __isDirectAccess.account = false;
        }
        setIsDirectAccess(_=>__isDirectAccess);
      }
    } catch (error) {
      console.error(
        "Error has occured on get user access control info :",
        error
      );
    } finally {
      // Handle data format
      dispatch(setUserAccessInfo(userRoleView));
    }
  };

  useEffect(() => {
    if (loginUserId.length > 0) {
      getUserInfo(loginUserId);
      console.log(authSelector.login.mail);
      findUserType(authSelector.login.mail);
    }
  }, [loginUserId]);

  const findUserType = (mail) => {
    // Extract the domain from the email address
    const domain = mail.split("@")[1];
    // Check if the domain is 'nokia.com'
    if (
      domain === "nokia.com" ||
      domain === "nokia-bell-labs.com" ||
      domain === "nokia-sbell.com"
    ) {
      dispatch(
        setProp({ slice: "marketplace", key: "isNokiaUser", value: true })
      );
    } else {
      dispatch(
        setProp({ slice: "marketplace", key: "isNokiaUser", value: false })
      );
    }
  };

  const getCountriesList = async () => {
    dispatch(
      setLoader({
        key: "isCountryLoading",
        value: true,
      })
    );
    try {
      let filterCondition = "is_allowed_for_marketplace = 1";
      const response = await OOT.getAllCountries(filterCondition);
      console.log(response.data.length, "res");
      if (response.status) {
        const updatedData = response.data.map((item) => {
          return {
            id: item.iso_2,
            value: item.name,
            is_merchant_products_allowed: item.is_merchant_products_allowed,
            short_region_code: item.short_region_code,
          };
        });
        dispatch(setCountryList(updatedData));
        dispatch(
          setLoader({
            key: "isCountryLoading",
            value: false,
          })
        );
      } else {
        dispatch(
          setLoader({
            key: "isCountryLoading",
            value: false,
          })
        );
      }
    } catch (error) {
      dispatch(
        setLoader({
          key: "isCountryLoading",
          value: false,
        })
      );
      console.error(error);
    }
  };

  /**
   * Load data
   */
  useQuery({
    queryKey: ["quesionneries"],
    queryFn: async ({ queryKey }) => {
      //@ts-ignore
      //onst [_key] = queryKey;
      const response = await OOT.getSubscriptionQuesionneries();
      return response;
    },
    onSuccess(result) {
      if (
        result?.data["Reason for canceling"] &&
        typeof result.data["Reason for canceling"]?.Options === "object"
      ) {
        const __questionnaire = Object.keys(
          result.data["Reason for canceling"].Options
        );
        // Frequency of invoicing subscription
        const frequencyObj =
          result?.data["Frequency of invoicing subscription"]?.Options ?? {};
        const __invoicingSubscriptionFrequency = Object.keys(frequencyObj).map(
          (fId) => ({ id: fId, value: frequencyObj[fId] })
        );
        dispatch(
          setQuestionnaire({
            questionnaire: __questionnaire,
          })
        );

        dispatch(
          setInvoicingSubscriptionFrequency({
            invoicingSubscriptionFrequency: __invoicingSubscriptionFrequency,
          })
        );
      }
    },
  });
  /**
   * Load partner list
   */
  useQuery({
    queryKey: ["partner_data"],
    // refetchInterval: 10 * 60 * 1000,
    queryFn: async () => {
      dispatch(
        setLoader({
          key: "isPartnerLoading",
          value: true,
        })
      );
      const response = await OOT.getSubscriptionPartnerData();
      return response;
    },
    onSuccess(rsPartnerData) {
      const __partnerList = rsPartnerData.data.partnerList
        ? rsPartnerData.data.partnerList
        : [];
      dispatch(
        setLoader({
          key: "isPartnerLoading",
          value: false,
        })
      );
      dispatch(
        setPartnerList({
          partnerList: __partnerList,
        })
      );
      // Make the partner selection by default if __partnerList.length === 1
      if (__partnerList.length === 1) {
        fetchSubscriptionNetworkData("fetch", __partnerList[0].id);
      }

      // if (__partnerList.length === 1) {
      //   fetchMarketplaceData("fetch", __partnerList[0].id);
      // }
    },
  });

  // Load market place partner list
  useQuery({
    queryKey: ["market_partner_data"],
    // refetchInterval: 10 * 60 * 1000,
    queryFn: async () => {
      dispatch(
        setLoader({
          key: "isPartnerLoading",
          value: true,
        })
      );
      const response = await OOT.getSubscriptionPartnerData(true);
      return response;
    },
    onSuccess(rsPartnerData) {
      const __partnerList = rsPartnerData.data.partnerList
        ? rsPartnerData.data.partnerList
        : [];
      dispatch(
        setLoader({
          key: "isPartnerLoading",
          value: false,
        })
      );
      dispatch(
        setMarketplacePartnerList({
          partnerList: __partnerList,
        })
      );
      if (__partnerList.length === 1) {
        fetchMarketplaceData("fetch", __partnerList[0].id, __partnerList);
      }
    },
  });

  // if (isAuthenticated && !isAuthorized) {
  //   return <Unauthorized />;
  // }

  if (isAuthenticated && marketplaceSelector.userAccessInfo.length === 0) {
    return (
      <div style={{ position: "absolute", inset: "50% 0" }}>
        <Loader />
      </div>
    );
  }

  return (
    <>
      <Routes>
        {isAuthenticated && marketplaceSelector.userAccessInfo.length > 0 && (
          <>
            <Route
              path="/"
              element={
                <Navigate
                  to={`/${marketplaceSelector.userAccessInfo[0]}`}
                  replace={true}
                />
              }
            />
            <Route
              index
              path="/subscription"
              element={
                !isAuthorized || !isDirectAccess.subscription ? (
                  <Unauthorized />
                ) : (
                  <Subscription />
                )
              }
            />
            <Route element={<ProtectedRoute requiredAccess="marketplace" />}>
              <Route path="/marketplace" element={<Marketplace />}>
                <Route index path="" element={<MarketplaceBody />} />

                <Route path="cart" element={<CartBody />} />
                <Route path="product/:id" element={<SelectedProductPage />} />
              </Route>
            </Route>
            <Route element={<ProtectedRoute requiredAccess="quotations" />}>
              <Route index path="/quotations" element={<Quotations />} />
            </Route>

            <Route
              path="/account"
              element={(!isAuthorized || !isDirectAccess.account) ? <Unauthorized /> : <Account />}
            />

            <Route element={<ProtectedRoute requiredAccess="admin" />}>
              <Route path="/admin" element={<Admin />} />
              <Route path="/roles" element={<Roles />} />
            </Route>
            <Route element={<ProtectedRoute requiredAccess="orders" />}>
              <Route path="/orders" element={<Orders />} />
            </Route>

            <Route path="/unauthorized" element={<Unauthorized />} />
          </>
        )}

        <Route
          path="/login"
          element={
            isAuthenticated ? (
              <Navigate
                to={`/${marketplaceSelector.userAccessInfo[0]}`}
                replace={true}
              />
            ) : (
              <Login />
            )
          }
        />
      </Routes>
    </>
  );
};
