import React from "react";
import OOT from "Constants/services/oot";
import { setUsers, setUsersLoading } from "Store/contentState/userManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Store/mainStore";


export const useUserActions = () => {
    const dispatch = useDispatch();
    const paginationSelector = useSelector((state: RootState) => state.pagination);
    const { limitPerpage, offset } = paginationSelector.paginationProps;

    const getAllUsersList = async () => {
        dispatch(setUsersLoading(true));
        try {
            const response = await OOT.getAllUsers("users.active = 1", limitPerpage, offset, "user");
            if (response.status) {
                const updatedUserList = response.data.map((user) => ({
                    id: user.id,
                    name: user.name || '',
                    email: user.email || '',
                    status: user.active === 1 ? 'Active' : 'Inactive',
                    userType: user.role.persona,
                    viewAccess: user.role.view,
                    userRoles: user.role.roles,
                    dataElement: user.role['data-element'],
                    oneStarNumber: user.role.one_star,
                    type: user.type,
                    threeStarNumber: user.role.three_star
                }));
                dispatch(setUsers(updatedUserList));
                dispatch(setUsersLoading(false));
            } else {
                dispatch(setUsersLoading(false));
                // console.log("No users available");
            }
        } catch (error) {
            dispatch(setUsersLoading(false));
            console.error(error);
        }
    }

    return getAllUsersList;


}